import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LegalView from "../views/LegalView.vue";

const routes = [
  {
    path: "/",
    name: "HomeView",
    component: HomeView,
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: LegalView,
    props: { pageId: "177" },
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    component: LegalView,
    props: { pageId: "181" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      let title = capitalize(to.hash.substring(1));
      document.title = `${process.env.VUE_APP_TITLE} | ${title}`;
      return {
        el: to.hash,
        behavior: "smooth",
        top: 60,
      };
    } else if (to.name) {
      if (to.name !== "HomeView") {
        document.title = `${process.env.VUE_APP_TITLE} | ${to.name}`;
      }
      return { top: 0 };
    }
  },
});

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default router;
