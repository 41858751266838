<template>
  <div class="bg-gray-800">
    <div
      class="flex space-x-4 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8 justify-end"
    >
      <a
        :href="social.link"
        v-for="social in socialLinks"
        :key="social.name"
        target="_blank"
        class="fill-gray-400 text-gray-400 py-2 px-1"
      >
        <inline-svg
          :src="require(`@/assets/logos/social/${social.name}.svg`)"
          class="h-5 w-5"
        />
      </a>
    </div>
  </div>

  <header
    class="bg-gradient-to-r from-pink-800 via-violet-900 to-indigo-900 sticky top-0 w-full h-16 flex items-center z-40 shadow-md"
  >
    <nav class="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
      <div class="w-full flex items-center justify-between">
        <router-link :to="{ name: 'HomeView' }">
          <img class="h-8 w-auto" src="@/assets/logo.png" alt="" />
        </router-link>

        <div class="ml-10 space-x-4">
          <div class="flex items-center">
            <div class="hidden ml-10 space-x-8 lg:block">
              <router-link
                v-for="item in mainNav"
                :key="item.name"
                class="text-base font-medium text-white hover:text-indigo-50"
                :to="{ name: 'HomeView', hash: '#' + item.hash }"
              >
                {{ item.name }}
              </router-link>

              <a
                :href="shopLink.link"
                target="_blank"
                class="inline-block bg-white py-2 px-4 border border-transparent rounded-md text-base font-medium text-black hover:bg-indigo-50"
              >
                {{ shopLink.name }}
              </a>
            </div>
            <button
              class="lg:hidden space-y-1.5 p-4"
              @click="openMobileNav = true"
            >
              <span class="block w-5 h-0.5 bg-white"></span>
              <span class="block w-8 h-0.5 bg-white"></span>
              <span class="block w-8 h-0.5 bg-white"></span>
            </button>
          </div>
        </div>
      </div>
    </nav>
    <TransitionRoot as="template" :show="openMobileNav">
      <Dialog
        as="div"
        class="fixed inset-0 overflow-hidden z-50"
        @close="openMobileNav = false"
      >
        <div class="absolute inset-0 overflow-hidden">
          <DialogOverlay class="absolute inset-0" />

          <div
            class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10"
          >
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <div class="pointer-events-auto w-screen max-w-md">
                <div
                  class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl"
                >
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle class="text-lg font-bold text-gray-900">
                        Navigation
                      </DialogTitle>
                      <div class="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          @click="openMobileNav = false"
                        >
                          <span class="sr-only">Close</span>
                          <XIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="mt-6 flex-1 px-4 sm:px-6">
                    <ul>
                      <li v-for="item in mainNav" :key="item.name">
                        <router-link
                          class="text-lg font-medium text-black w-full py-2 border-b block"
                          :to="{ name: 'HomeView', hash: '#' + item.hash }"
                          @click="openMobileNav = false"
                        >
                          {{ item.name }}
                        </router-link>
                      </li>
                      <li class="mt-4">
                        <a
                          :href="shopLink.link"
                          target="_blank"
                          class="block py-2 px-4 border border-transparent rounded-md text-base font-medium bg-gradient-to-b from-indigo-800 to-indigo-900 text-white text-center"
                        >
                          {{ shopLink.name }}
                        </a>
                      </li>
                    </ul>
                    <div class="flex justify-between mt-4">
                      <a
                        :href="social.link"
                        v-for="social in socialLinks"
                        :key="social.name"
                        target="_blank"
                        class="fill-gray-300 text-gray-300 p-3 bg-gray-700 rounded-lg"
                      >
                        <inline-svg
                          :src="
                            require(`@/assets/logos/social/${social.name}.svg`)
                          "
                          class="h-6 w-6"
                        />
                      </a>
                    </div>

                    <!-- /End replace -->
                  </div>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </header>

  <router-view />

  <footer class="bg-gray-800">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <div class="md:flex md:items-center md:justify-between">
        <div class="flex space-x-4 justify-end md:order-2">
          <a
            :href="social.link"
            v-for="social in socialLinks"
            :key="social.name"
            target="_blank"
            class="fill-gray-400 text-gray-400 p-3 bg-gray-700 rounded-lg hover:rounded-3xl ease-in-out duration-300"
          >
            <inline-svg
              :src="require(`@/assets/logos/social/${social.name}.svg`)"
              class="h-5 w-5"
            />
          </a>
        </div>

        <div class="mt-8 md:mt-0 md:order-1 flex justify-between">
          <p class="text-center text-base text-gray-400">
            &copy; {{ year }} Simtschuk
          </p>
          <div class="flex justify-center space-x-6 md:ml-24">
            <router-link
              v-for="item in footerNav"
              :key="item.name"
              class="text-gray-400 hover:text-gray-500"
              :to="{ name: item.name, params: { pageId: item.id } }"
            >
              {{ item.name }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { ref } from "vue";
import InlineSvg from "vue-inline-svg";

import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    InlineSvg,
  },
  setup() {
    const openMobileNav = ref(false);

    const year = new Date().getFullYear();
    const mainNav = [
      { name: "Start", hash: "start" },
      { name: "Musik", hash: "musik" },
      { name: "Videos", hash: "videos" },
      { name: "Live", hash: "live" },
      { name: "Presse", hash: "presse" },
      { name: "Kontakt", hash: "kontakt" },
    ];

    const footerNav = [
      { name: "Impressum", id: "177" },
      { name: "Datenschutz", id: "181" },
    ];

    const shopLink = {
      name: "Shop",
      // link: "https://simtschuk.myshopify.com/collections/all",
      link: "https://studiovierkant-press.de/products/simtschuk-yuffimodel-a3-kalender",
    };

    const socialLinks = [
      { name: "instagram", link: "https://www.instagram.com/_simtschuk_/" },
      {
        name: "youtube",
        link: "https://www.youtube.com/channel/UCh7rWInqxhITqgKbN0KIIzg",
      },
      {
        name: "spotify",
        link: "https://open.spotify.com/artist/1o5WJHgpfTSVhUfDc1mrMA?si=9b79c27289d1406a",
      },
      {
        name: "applemusic",
        link: "https://music.apple.com/au/artist/simtschuk/1479114918",
      },
      {
        name: "amazon-music",
        link: "https://music.amazon.de/artists/B07XHVGV5Q/simtschuk",
      },
      {
        name: "deezer",
        link: "https://www.deezer.com/de/artist/73510932",
      },
    ];

    return {
      openMobileNav,
      year,
      mainNav,
      footerNav,
      socialLinks,
      shopLink,
    };
  },
};
</script>
