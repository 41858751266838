<template>
  <section
    id="kontakt"
    class="h-screen flex justify-center items-center bg-cover bg-top relative"
    :style="`background-image: url(${data.image.url}) `"
  >
    <div class="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
      <div
        class="bg-gradient-to-b from-violet-800 to-indigo-900 text-white px-5 py-8 max-w-md rounded-lg shadow-md"
      >
        <h2 class="text-2xl font-bold lg:text-3xl mb-4">Kontakt</h2>
        <div v-html="data.text" class="prose-lg w-full"></div>
      </div>
    </div>
    <div class="absolute inset-0 bg-indigo-200 mix-blend-multiply"></div>
  </section>
</template>

<script>
export default {
  name: "ContactSession",
  props: {
    data: Object,
  },
  // setup(props) {
  //   console.log(props.data);
  // },
};
</script>

<style></style>
