<template>
  <section
    id="start"
    class="h-screen flex justify-center items-center bg-cover bg-top relative"
    :style="`background-image:url('${data.image}')`"
  >
    <div class="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
      <div class="text-white mt-48">
        <h1
          v-if="data.title"
          class="text-4xl font-bold md:text-6xl md:max-w-2xl"
        >
          {{ data.title }}
        </h1>
        <h2 v-if="data.subtitle">{{ data.subtitle }}</h2>
        <div
          v-if="data.text"
          class="mt-8 text-lg font-medium md:max-w-md md:text-xl"
          v-html="data.text"
        ></div>
      </div>
    </div>
    <div class="absolute inset-0 bg-indigo-200 mix-blend-multiply"></div>
  </section>
</template>

<script>
export default {
  name: "HeroSection",
  props: {
    data: Object,
  },
  // setup(props) {
  //   console.log(props.data);
  // },
};
</script>
