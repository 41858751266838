<template>
  <li
    :class="[
      hideItem ? 'hidden' : 'grid',
      'py-4 border-b grid-cols-3 md:grid-cols-5',
    ]"
  >
    <div
      :class="[
        disableItem ? 'text-gray-400' : '',
        'italic font-medium col-span-1 md:not-italic text-lg',
      ]"
    >
      {{ date }}
    </div>
    <div class="col-span-3 md:col-span-3">
      <p :class="[disableItem ? 'text-gray-400' : '', 'text-lg']">
        {{ item.city }}, {{ item.location }}
      </p>
      <p
        :class="[
          disableItem ? 'text-gray-400' : '',
          'text-2xl mt-1 mb-2 md:my-0 md:text-xl font-bold',
        ]"
      >
        {{ item.event }}
      </p>
    </div>
    <div>
      <a
        :href="item.button.link"
        v-if="item.button.text.length !== 0 && item.button.link.length !== 0"
        target="_blank"
        :class="[
          disableItem
            ? 'bg-gray-300 text-gray-400 pointer-events-none'
            : 'bg-indigo-800 hover:bg-indigo-900 text-white',
          'px-6 py-2 rounded-md  ease-in-out duration-300 flex justify-center items-center  w-32 font-medium md:ml-auto',
        ]"
      >
        {{ item.button.text }}
      </a>
    </div>
  </li>
</template>

<script setup>
const { computed } = require("vue");
const props = defineProps({
  item: Object,
});

let today = new Date();
let dateObject = new Date(props.item.date);

const date = computed(() => {
  var options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  return dateObject.toLocaleDateString("de-DE", options);
});

const disableItem = computed(() => {
  if (today > dateObject) {
    return true;
  } else {
    return false;
  }
});

const hideItem = computed(() => {
  if (today.getYear() > dateObject.getYear()) {
    return true;
  } else {
    return false;
  }
});
</script>
