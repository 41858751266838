<template>
  <section id="live" class="py-24">
    <div class="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
      <div class="w-full text-center max-w-lg mx-auto mb-20">
        <h2 class="text-4xl font-bold lg:text-5xl mb-4">Simtschuk Live</h2>
        <div class="prose-lg w-full" v-html="data.text"></div>
      </div>
      <ul class="max-w-4xl mx-auto">
        <template v-if="allItemsHidden()">
          <li class="text-center text-xl">Aktuell sind keine Events geplant</li>
        </template>
        <template v-else>
          <LiveItem v-for="(row, index) in liveData" :key="index" :item="row" />
        </template>
      </ul>
    </div>
  </section>
</template>

<script>
import { ref, watchEffect } from "vue";
import LiveItem from "@/components/LiveItem.vue";
export default {
  name: "LiveSection",
  props: {
    data: Object,
  },
  components: {
    LiveItem,
  },
  setup(props) {
    console.log(props.data);
    const liveData = ref(props.data.table);
    watchEffect(() => {
      liveData.value.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });
    });

    function allItemsHidden() {
      let today = new Date();
      return liveData.value.every((item) => {
        let dateObject = new Date(item.date);
        return dateObject < today;
      });
    }

    return {
      liveData,
      allItemsHidden,
    };
  },
};
</script>
