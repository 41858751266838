<template>
  <section id="presse" class="py-24 bg-slate-200">
    <div class="w-full text-center max-w-lg mx-auto mb-20">
      <h2 class="text-4xl font-bold lg:text-5xl mb-4">Presse</h2>
      <div class="prose-lg w-full" v-html="data.text"></div>
    </div>
    <div class="relative">
      <div class="max-w-7xl mx-auto">
        <div class="w-full md:w-1/2 p-4 sm:p-6 lg:p-8">
          <h3 class="text-2xl font-bold lg:text-2xl mb-4">Künstlerbio</h3>
          <div v-html="data.bio" class="prose-lg w-full"></div>
        </div>
      </div>
      <div class="hidden md:block h-full w-1/2 absolute bottom-0 right-0">
        <img
          class="h-full w-full rounded-l-lg object-cover opacity-80 shadow-xl"
          src="https://admin.simtschuk.de/wp-content/uploads/2022/04/33KIn9Sg-scaled.jpeg"
        />
      </div>
    </div>
    <div class="mt-24 px-4 sm:px-6 lg:px-8 max-w-7xl w-full mx-auto">
      <h3 class="text-2xl font-bold lg:text-2xl mb-4">Pressefotos</h3>
      <div class="grid md:grid-cols-3 gap-6">
        <a
          v-for="(image, index) in data.pressimages"
          :key="index"
          :href="image.image.url"
          download
          target="_blank"
          class="relative group"
        >
          <figure class="w-full h-96 md:h-72">
            <img
              :src="image.image.sizes.medium_large"
              class="object-cover w-full h-full rounded-lg shadow-lg"
            />
          </figure>
          <div
            class="overflow-hidden h-0 group-hover:h-10 absolute w-full bottom-0 bg-gradient-to-r from-violet-800 to-indigo-900 text-white rounded-br-lg rounded-bl-lg ease-in-out duration-300 flex items-center justify-center"
          >
            Vergrößern
          </div>
        </a>
      </div>
    </div>
    <div class="max-w-7xl mx-auto mt-24 px-4 sm:px-6 lg:px-8">
      <h3 class="text-2xl font-bold lg:text-2xl mb-4">Downloads</h3>
      <template v-for="(download, index) in data.downloads" :key="index">
        <div class="py-1 border-b border-slate-800 max-w-lg">
          <a
            :href="download.download.file.url"
            download
            class="hover:text-indigo-900 text-lg"
            target="_blank"
          >
            {{ download.download.title }}
            <span class="text-gray-600 text-base"
              >({{ printFilesize(download.download.file.filesize) }})</span
            >
          </a>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
export default {
  name: "PressSection",
  props: {
    data: Object,
  },
  setup() {
    function printFilesize(size) {
      if (size > 1000000) {
        size = size / 1000000;
        return size.toFixed(2) + " MB";
      } else {
        size = size / 1000;
        return size.toFixed(2) + " KB";
      }
    }

    return {
      printFilesize,
    };
  },
};
</script>
