<template>
  <div v-if="data">
    <HeroSection :data="data.hero" />
    <MusicSection :data="data.music" />
    <VideoSection :data="data.videos" />
    <LiveSection :data="data.live" />
    <PressSection :data="data.press" />
    <ContactSection :data="data.contact" />
  </div>
  <div v-else class="h-screen flex items-center justify-center">
    <span
      class="animate-ping inline-flex h-24 w-24 rounded-full bg-indigo-800 opacity-75"
    ></span>
  </div>
</template>

<script>
import HeroSection from "@/sections/HeroSection.vue";
import MusicSection from "@/sections/MusicSection.vue";
import VideoSection from "@/sections/VideoSection.vue";
import LiveSection from "@/sections/LiveSection.vue";
import PressSection from "@/sections/PressSection.vue";
import ContactSection from "@/sections/ContactSection.vue";
import { ref, watchEffect } from "vue";
import axios from "axios";

export default {
  name: "HomeView",
  components: {
    HeroSection,
    MusicSection,
    VideoSection,
    LiveSection,
    PressSection,
    ContactSection,
  },
  setup() {
    const data = ref(null);

    watchEffect(() => {
      axios
        .get(
          "https://admin.simtschuk.de/wp-json/wp/v2/pages/57?_fields=acf&acf_format=standard"
        )
        .then((response) => {
          data.value = response.data.acf;
        })
        .finally(() => {
          if (window.location.hash) {
            let hash = window.location.hash.substring(1);
            const element = document.querySelector(`#${hash}`);
            const topPos =
              element.getBoundingClientRect().top + (window.pageYOffset - 60);
            window.scrollTo({ top: topPos, behavior: "smooth" });
          }
        });
    });

    return {
      data,
    };
  },
};
</script>
