<template>
  <div v-if="data" class="min-h-screen">
    <section class="py-24">
      <div class="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div v-html="data.text" class="prose-lg w-full"></div>
      </div>
    </section>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import axios from "axios";

export default {
  name: "LegalView",
  props: {
    pageId: String,
  },
  setup(props) {
    const data = ref(null);

    watchEffect(() => {
      axios
        .get(
          `https://admin.simtschuk.de/wp-json/wp/v2/pages/${props.pageId}?_fields=acf&acf_format=standard`
        )
        .then((response) => {
          data.value = response.data.acf;
          console.log(data.value);
        });
    });

    return {
      data,
    };
  },
};
</script>
