<template>
  <div
    class="cursor-pointer hover:scale-105 hover:translate-y-2 ease-in-out duration-300"
    @click="toggleModal"
  >
    <figure class="w-full relative">
      <span
        class="absolute top-0 right-0 py-1 px-2 text-white rounded-lg rounded-br-none rounded-tl-none bg-gradient-to-r from-indigo-800 to-indigo-900"
      >
        {{ data.type }}
      </span>
      <img
        :src="data.image"
        :alt="data.title"
        class="rounded-lg object-cover w-full shadow-md"
      />
    </figure>
    <div>
      <h3 class="mt-2 text-lg font-bold text-center">
        {{ data.title }}
        <span v-if="data.year.length !== 0"> ({{ data.year }}) </span>
      </h3>
    </div>
  </div>

  <ModalContainer :showModal="showModal" v-on:modalClosed="toggleModal">
    <div class="flex flex-wrap">
      <figure class="w-full h-64 md:h-96 md:w-80">
        <img
          :src="data.image"
          :alt="data.title"
          class="rounded-lg object-cover w-full h-full shadow-md"
        />
      </figure>
      <div class="shrink-0 p-8 w-full md:w-auto">
        <span class="text-xs">
          {{ data.type }}
        </span>
        <h3 class="font-bold text-2xl mb-4">{{ data.title }}</h3>
        <div class="w-full border-t border-gray-300 mb-6"></div>

        <div class="grid grid-cols-2 gap-3">
          <template v-for="(link, key, index) in data.links" :key="index">
            <a
              :href="link"
              v-if="link"
              target="_blank"
              class="bg-indigo-800 px-6 py-1 rounded-md from-violet-800 to-indigo-900 hover:bg-indigo-900 ease-in-out duration-300 flex justify-center items-center"
            >
              <img
                :src="require(`@/assets/logos/button/${key}.png`)"
                :alt="key"
                class="w-28"
              />
            </a>
          </template>
        </div>
      </div>
    </div>
  </ModalContainer>
</template>

<script>
import { ref } from "vue";
import ModalContainer from "@/components/ModalContainer";
export default {
  name: "MusicItem",
  props: {
    data: Object,
  },
  components: {
    ModalContainer,
  },
  setup() {
    // console.table(props.data);
    const showModal = ref(false);

    function toggleModal() {
      showModal.value = !showModal.value;
    }
    return {
      showModal,
      toggleModal,
    };
  },
};
</script>

<style></style>
