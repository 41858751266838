<template>
  <section id="musik" class="py-24">
    <div class="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
      <div class="w-full text-center max-w-lg mx-auto mb-20">
        <h2 class="text-4xl font-bold lg:text-5xl mb-4">Musik</h2>
        <div class="prose-lg w-full" v-html="data.text"></div>
      </div>
      <div class="grid grid-cols-2 gap-5 md:grid-cols-4 lg:gap-12">
        <MusicItem
          v-for="(item, index) in data.cards"
          :key="index"
          :data="item"
        />
      </div>
    </div>
  </section>
</template>

<script>
import MusicItem from "@/components/MusicItem.vue";

export default {
  name: "MusicSection",
  props: {
    data: Object,
  },
  components: {
    MusicItem,
  },
  // setup(props) {
  //   console.log(props.data);
  // },
};
</script>

<style></style>
