<template>
  <section id="videos" class="py-24 bg-slate-200">
    <div class="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
      <div class="w-full text-center max-w-lg mx-auto mb-20">
        <h2 class="text-4xl font-bold lg:text-5xl mb-4">Videos</h2>
        <div class="prose-lg w-full" v-html="data.text"></div>
      </div>
      <div
        class="grid grid-cols-1 gap-5 md:grid-cols-2 lg:gap-12"
        v-if="consent"
      >
        <div
          v-for="(video, index) in data.yt_videos"
          :key="index"
          class="relative h-0 overflow-hidden max-w-full w-full"
          style="padding-bottom: 56.25%"
        >
          <iframe
            :src="`https://www.youtube-nocookie.com/embed/${video.id}`"
            frameborder="0"
            class="shadow-md rounded-md absolute top-0 left-0 w-full h-full"
            allow="encrypted-media; fullscreen;"
            v-if="
              consent.functionality !== false && consent.targeting !== false
            "
          />
          <div
            v-else
            class="shadow-md rounded-md absolute top-0 left-0 w-full h-full p-4 bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300"
          >
            Wegen den gesetzten Cookie-Einstellungen kann das eingebettete
            YouTube-Video nicht angezeit werden.
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, inject } from "vue";
export default {
  name: "VideoSection",
  props: {
    data: Object,
  },
  setup() {
    const consent = ref(null);

    consent.value = inject("$cookies").get("cookie_consent_level");

    return {
      consent,
    };
  },
};
</script>
